﻿.entries-list {
    height: 45px;
    width: 100%;
    padding: 0 $padding-side-page;
    overflow: hidden;

    @media all and (max-width: 1500px) and (min-width: 901px) {
        padding: 0 calc($padding-side-page / 2);
    }

    @media all and (max-width: 900px) and (min-width: 401px) {
        padding: 0 calc($padding-side-page / 5) calc($padding-side-page / 5) calc($padding-side-page / 5);
    }

    @media all and (max-width: 400px) {
        padding: 0 calc($padding-side-page / 10) calc($padding-side-page / 5) calc($padding-side-page / 10);
    }

    .entries-list__wrapper {
        display: flex;
        column-gap: 1.875rem;
        overflow-x: scroll;

        .entries-list__item {
            a {
                width: max-content;
                font-weight: bold;
            }

            &.-gray {
                background: #F2F2F2;
                text-align: center;
                padding: 1rem;
            }
        }

        &:not(.-overflown) {
            justify-content: center;
        }
    }
}
