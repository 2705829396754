﻿.product-slider {
    padding: calc($padding-side-page / 2);
    text-align: center;
    background: #f2f2f2;

    &.-no-background {
        padding-top: 0;
        padding-bottom: 0;
    }

    @media all and (max-width: 640px) and (min-width: 401px) {
        padding: calc($padding-side-page / 2) calc($padding-side-page / 5);
    }

    @media all and (max-width: 400px) {
        padding: calc($padding-side-page / 2) calc($padding-side-page / 10);
    }

    @import '../_owlSlider';

    .product-slider__wrapper {
        @media all and (min-width: 641px) {
            padding-right: 5rem;
        }

        @media all and (max-width: 40rem) {
            padding-bottom: 3.75rem;
        }

        .owl-stage {
            padding: 1.25rem 0;

            .product-slider__item {
                width: 20.625rem;
                height: 28.128rem;
                background: white;
                padding: 1.25rem 1.875rem;
                box-shadow: 0 1px 6px 1px #00000016;

                .item__image {
                    height: 270px;
                    width: 270px;
                }

                .item__content {
                    text-align: left;

                    a {
                        text-decoration: none;
                    }

                    .name {
                        min-height: 2.5rem;
                    }

                    @import "../_tagsWrapper.scss";

                    .content__bottom {
                        margin-top: 1rem;
                        height: 2.5rem;

                        .price {
                            font-weight: bold;
                            font-size: 1.125rem;
                        }

                        &:not(.-has-promo) {
                            display: flex;
                            flex-direction: column;
                            justify-content: flex-end;
                        }

                        &.-has-promo {
                            display: grid;
                            grid-template-rows: 1fr 1fr;
                            grid-template-columns: 2.5rem auto;
                            column-gap: 0.625rem;

                            .discount {
                                grid-column: 1/2;
                                grid-row: 1/3;
                                align-self: center;
                                background: #333;
                                height: 2.5rem;
                                width: 2.5rem;
                                border-radius: 50%;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                color: white;
                                font-weight: bold;
                            }

                            .crossedout {
                                text-decoration: line-through;
                            }
                        }
                    }
                }

                &:not(.-has-category) {
                    .item__content {
                        margin-top: 1.25rem;
                    }
                }

                &.-has-category {
                    .item__category {
                        margin: 5px 0;
                        padding: .115rem .325rem;
                        width: fit-content;

                        p {
                            color: white;
                            font-size: .5625rem;
                            text-transform: uppercase;
                        }
                    }
                }
            }
        }

        .owl-nav {
            .owl-prev {
                display: none;
            }

            .owl-next {
                right: .125rem;

                @media all and (max-width: 400px) {
                    right: .125rem;
                }
            }
        }

        .owl-dots {
            @media all and (max-width: 40rem) {
                display: none;
            }
        }
    }
}
