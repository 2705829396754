﻿.owl-carousel {
    display: block !important;
    position: relative;

    .owl-nav {
        .owl-prev, .owl-next {
            position: absolute;
            top: calc(50% - 2.8125rem);
            width: fit-content;
            background: white;
            box-shadow: 0 0 0 1px;
            border-radius: 50%;
            width: 2.8125rem;
            height: 2.8125rem;
            display: flex;
            align-items: center;
            justify-content: center;

            &:before {
                font-family: 'ElegantIcons';
                color: black;
                font-size: 2rem;
            }
        }

        .owl-prev {
            left: 3.125rem;
            text-indent: -0.1875rem;

            @media all and (max-width: 1500px) and (min-width: 601px) {
                left: 1rem;
            }

            &:before {
                content: "\0034";
            }
        }

        .owl-next {
            right: 3.125rem;

            @media all and (max-width: 1500px) and (min-width: 601px) {
                right: 1rem;
            }

            &:before {
                content: "\0035";
            }
        }
    }

    .owl-dots {
        &:not(.disabled) {
            display: flex;
            gap: .625rem;
            width: fit-content;
            margin: 0.9375rem auto 0.9375rem auto;

            .owl-dot {
                width: 10px;
                height: 10px;
                border: solid 1px #333;
                border-radius: 50%;
                position: relative;

                &.active {
                    &:before {
                        content: '';
                        background: #333;
                        width: 6px;
                        height: 6px;
                        display: block;
                        left: 1px;
                        top: 1px;
                        position: absolute;
                        border-radius: 50%;
                    }
                }
            }
        }
    }
}
