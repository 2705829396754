﻿.banner-slider {
    @import '../_owlSlider';
    position: relative;

    .banner-slider__wrapper {
        .owl-item {
            height: fit-content;
        }

        .banner-slider__item {
            display: flex;
            position: relative;

            .-mobile {
                display: none;
            }

            @media all and (min-width: 64.023rem) {
                aspect-ratio: 1920/850;
                height: calc(100dvw / (1920 / 850));
            }

            @media all and (max-width: 64rem) and (min-width: 37.523rem) {
                height: 28.3125rem;
            }

            @media all and (max-width: 37.5rem) {
                aspect-ratio: 600/895;
                height: calc(100dvw / (600/895));

                .-mobile {
                    display: block;
                }

                .-desktop {
                    display: none;
                }
            }

            &.-full, &.-scind {
                .-desktop {
                    width: 100%;
                }
            }

            &.-asym {
                .-desktop {
                    &[data-index="0"] {
                        width: 73.8%;
                    }

                    &[data-index="1"] {
                        width: 26.2%;
                    }
                }
            }

            &.-scind {

            }

            .item__description {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                text-align: center;
                max-width: 60rem;
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                background: rgb(255,255,255);
                background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(0,0,0,0.2) 50%, rgba(255,255,255,0) 100%);
                z-index: 2;

                @media all and (max-width: 37.5rem) {
                    justify-content: flex-end;
                    padding-bottom: 4.375rem;
                    background: rgb(255,255,255);
                    background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(0,0,0,0.2) 100%);
                }

                h1, .-title-h1 {
                    max-width: 20.9375rem;

                    @media all and (min-width: 64.023rem) {
                        font-size: 3rem;
                        max-width: 37.5rem;
                    }
                }

                h2, h2 > p, h2 * {
                    max-width: 20.9375rem;
                    margin: .3125rem 0 1.875rem;
                    text-transform: none;
                    font-weight: inherit;
                    font-size: 1.375rem;

                    @media all and (min-width: 64.023rem) {
                        font-size: 2.5rem;
                        max-width: 40.625rem;
                    }

                    p {
                        margin: 0;
                    }
                }
            }

            .item__content {
                height: 100%;

                video, img {
                    @media all and (min-width: 37.529rem) {
                        max-height: 100%;
                    }

                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                }

                img, video {
                    @media all and (max-width: 64rem) and (min-width: 37.529rem) {
                        height: 100%;
                        object-fit: cover;
                    }
                }

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }

        .owl-dots {
            position: absolute;
            bottom: .625rem;
            left: 50%;
            transform: translateX(-50%);

            .owl-dot {
                border: solid 1px white !important;
                box-shadow: #00000029 1px 1px 1px;
                cursor: pointer;

                &.active {
                    background: white;

                    &:before {
                        content: none !important;
                    }
                }
            }
        }

        .owl-nav {
            @media all and (max-width: 37.5rem) {
                display: none;
            }

            .owl-prev, .owl-next {
                background: none;
                box-shadow: none;

                &:before {
                    color: white;
                }
            }
        }

        &:not(.owl-loaded) {
            @media all and (max-width: 600px) {
                aspect-ratio: 600 / 875;
            }

            & + .banner-slider__controls {
                display: none;
            }
        }
    }

    .banner-slider__controls {
        @media all and (min-width: 641px) {
            display: none;
        }

        @media all and (max-width: 640px) {
            z-index: 2;
            position: absolute;
            display: block;
            box-shadow: 0 3px 6px #00000010;
            height: 2.5rem;
            bottom: 0;
            width: 100%;

            .controls__wrapper {
                display: flex;

                .controls__prev-next {
                    display: flex;
                    justify-content: space-between;
                    width: 100%;

                    & > * {
                        width: 2.4rem;
                        height: 2.4rem;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        &:before {
                            font-family: ElegantIcons;
                            font-size: 1.5625rem;
                        }

                        &.next {
                            &:before {
                                content: '5';
                                color: white;
                            }
                        }

                        &.prev {
                            &:before {
                                content: '4';
                                color: white;
                            }
                        }
                    }
                }
            }
        }
    }
}
