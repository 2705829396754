#main-content {
    .homepage {
        .homepage-wrapper {
            $padding-side-page: 6.25rem;

            //display: flex;
            //flex-direction: column;
            //gap: 3.125rem;
            //@media all and (max-width: 640px) {
            //    gap: 1.5625rem;
            //}

            section:not(:last-of-type) {
                margin-bottom: 5rem;
            }

            section:first-of-type {
                margin: 0 0 2.5rem;
            }

            @import '../../components/sections/_bannerSlider';
            @import '../../components/sections/_entriesList';
            @import '../../components/sections/_cardsSlider';
            @import '../../components/sections/_gridList';
            @import '../../components/sections/_simpleImages';
            @import '../../components/sections/_productSlider';
            @import '../../components/sections/_subsectionsEntries';
            @import '../../components/sections/_partners';
            @import '../../components/sections/_simpleText';
        }
    }
}
