﻿.partners {
    padding: 0 $padding-side-page;

    @media all and (max-width: 1500px) and (min-width: 901px) {
        padding: 0 calc($padding-side-page / 2);
    }

    @media all and (max-width: 900px) and (min-width: 401px) {
        padding: 0 calc($padding-side-page / 5);
    }

    @media all and (max-width: 400px) {
        padding: 0 calc($padding-side-page / 10);
    }

    .partners__wrapper {
        display: flex;
        flex-direction: column;
        gap: 3.125rem;

        h2 {
            text-align: center;
        }

        .partners__logo {
            .logo__wrapper {
                display: flex;
                gap: 3.125rem;
                justify-content: center;
                flex-wrap: wrap;

                @media all and (max-width: 1240px) and (min-width: 401px) {
                    padding: 0 11%;
                }

                .logo__item {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    gap: 1.25rem;
                    width: 11.25rem;

                    @media all and (max-width:89.375rem) and (min-width: 75.9375rem) {
                        flex-basis: 29%;
                    } 

                    .item__image {
                        width: fit-content;

                        img {
                            aspect-ratio: 1/1;
                            width: 11.25rem;
                            height: 11.25rem;
                            border-radius: 50%;
                        }
                    }

                    .item__content {
                        text-align: center;
                    }
                }
            }
        }

        .partners__description {
            .description__wrapper {
                display: flex;
                flex-direction: column;
                gap: 1.25rem;
                align-items: center;
            }
        }
    }
}
