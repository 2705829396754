﻿.subsections-entries {
    padding: 0 $padding-side-page;
    overflow: hidden;

    @media all and (max-width: 1500px) and (min-width: 901px) {
        padding: 0 calc($padding-side-page / 2);
    }

    @media all and (max-width: 900px) and (min-width: 401px) {
        padding: 0 calc($padding-side-page / 5);
    }

    @media all and (max-width: 400px) {
        padding: 0 calc($padding-side-page / 10);
    }

    .subesctions-entries__wrapper {
        display: flex;
        flex-direction: column;
        gap: 3.125rem;

        .subsections-entries__titles {
            display: flex;
            gap: 3.125rem;
            overflow: auto;

            &:not(.-overflown) {
                justify-content: center;
            }

            @media all and (max-width: 640px) {
                gap: 1.875rem;
            }

            .titles__item {
                h2 {
                    white-space: pre;
                    cursor: pointer;
                    transition: all .5s;
                    user-select: none;

                    &:hover {
                        color: #333;
                    }

                    &:not(.-active) {
                        color: #666;
                    }

                    &.-active {
                        text-decoration: underline;
                    }
                }
            }
        }

        .subsections-entries__content {
            .content__item {
                &:not(.-active) {
                    display: none;
                }

                .item__wrapper {
                    display: flex;
                    gap: 3.125rem;
                    min-height: 337px;

                    &.-overflown {
                        overflow-x: hidden;

                        @media all and (max-width: 40rem) {
                            overflow-x: auto;
                        }
                    }

                    &:not(.-overflown) {
                        justify-content: center;
                    }

                    @media all and (max-width: 1239px) and (min-width: 901px) {
                        padding-right: 3.125rem;
                    }

                    article {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        gap: 0.625rem;
                        max-width: 18.75rem;
                        width: 100%;

                        @media all and (max-width: 1239px) {
                            width: 18.75rem;
                            max-width: inherit;
                        }

                        picture {
                            min-height: 300px;
                            width: 18.75rem;
                        }

                        &:hover {
                            img {
                                filter: brightness(.7);
                            }
                        }

                        img {
                            aspect-ratio: 1/1;
                            object-fit: cover;
                            width: 18.75rem;
                            transition: filter .5s;
                        }
                    }

                    .owl-nav {
                        display: block;

                        .owl-prev {
                            display: none;
                        }

                        .owl-next {
                            right: 1px;
                        }
                    }

                    .owl-dots {
                        display: none;
                    }
                }
            }
        }
    }
}
