﻿section.simple-text {
    max-width: 78.75rem;
    margin-left: auto;
    margin-right: auto;
    padding: 0 1.875rem;

    .text__wrapper {
        text-align: center;

        h1 {
            margin: 1.5625rem 0;
        }

        .text__content {
            margin-top: 1.5625rem;
        }
    }

    &:not(:has(.text__content)) {
        margin-bottom: 2.5rem;
    }
}
 