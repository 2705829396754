﻿.grid-list {
    text-align: center;
    padding: 0 $padding-side-page;
    display: flex;
    flex-direction: column;
    gap: 3.125rem;
    display: grid;

    @media all and (max-width: 1500px) and (min-width: 901px) {
        padding: 0 calc($padding-side-page / 2);
    }

    @media all and (max-width: 900px) and (min-width: 401px) {
        padding: 0 calc($padding-side-page / 5);
    }

    @media all and (max-width: 400px) {
        padding: 0 calc($padding-side-page / 10);
    }

    .grid-list__wrapper {
        display: grid;
        grid-template-columns: repeat(3, minmax(0, 25rem));
        grid-template-rows: repeat(2, 15.625rem);
        gap: 3.125rem;
        width: fit-content;
        margin: auto;

        @media all and (max-width: 1239px) and (min-width: 901px) {
            grid-template-rows: repeat(2, minmax(0, 21.875rem));
            grid-template-columns: repeat(2, minmax(0, 21.875rem));
        }

        @media all and (max-width: 900px) {
            display: flex;
            flex-direction: column;
            width: 100%;
            max-width: 35rem;
        }

        @media all and (max-width: 640px) {
            gap: 1.5625rem;
        }

        .grid-list__item {
            height: 100%;
            max-height: fit-content;
            position: relative;
            overflow: hidden;

            @media all and (max-width: 900px) {
                width: 100%;
            }

            &:hover, &.-shadow {
                .item__image:has(.item__image.fbo) {
                    img {
                        filter: brightness(.7);
                    }
                }
            }

            .item__image {
                height: 100%;
                max-height: 100%;

                img {
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                    transition: filter .5s;

                    @media all and (max-width: 1239px) {
                        width: 100%;
                        aspect-ratio: 1/1;
                    }
                }
            }

            .item__content {
                position: absolute;
                height: 100%;
                width: 100%;
                top: 0;
                left: 0;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                padding: 1.25rem;

                .content__main-infos {
                    text-align: left;
                    height: 100%;

                    h3 {
                        display: flex;
                        flex-direction: column;
                        gap: .3125rem;
                    }
                }

                .content__secondary-infos {
                    display: flex;
                    justify-content: flex-end;

                    p {
                        display: flex;
                        align-items: center;
                        width: fit-content;
                        gap: .625rem;

                        span {
                            width: 0;
                            overflow: hidden;
                            white-space: pre;
                            transition: all .5s;

                            @media all and (max-width: 640px) {
                                width: inherit;
                            }
                        }

                        &:after {
                            font-family: 'ElegantIcons';
                            content: '\004C';
                            background: #333;
                            display: block;
                            height: 2rem;
                            width: 2rem;
                            border-radius: 50%;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            transition: all .5s;
                        }

                        &:hover {
                            span {
                                @media all and (min-width: 641px) {
                                    width: 100px;
                                }
                            }

                            &:after {
                                @media all and (min-width: 641px) {
                                    transform: rotate(180deg);
                                }
                            }
                        }
                    }
                }

                h3, a, p, span {
                    color: white;
                }
            }

            &:first-of-type {
                @media all and (min-width: 1240px) {
                    grid-row: 1/3;
                }
            }

            &:last-of-type {
                @media all and (min-width: 1240px) {
                    grid-column: 2/4;
                }
            }
        }
    }

    &.-more-elements-5 {
        .grid-list__item {
            .item__image {
                height: 100%;
            }

            &:first-of-type {
                @media all and (max-width: 1240px) {
                    grid-column: 1 / 3;
                }
            }

            &:last-of-type {
                grid-column: inherit;
            }
        }

        &.-blur {
            .grid-list__item:first-of-type {
                overflow: hidden;
                pointer-events: none;

                @media all and (max-width: 1240px) {
                    grid-column: 1 / 3;
                }

                &:hover {
                    .item__image {
                        img {
                            filter: blur(5px) brightness(.7);
                        }
                    }
                }

                .item__image {
                    img {
                        transform: scale(1.1);
                        filter: blur(5px) brightness(.7);
                        transition: all .5s;
                    }
                }

                .item__content {
                    .content__main-infos {
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        a {
                            h3 {
                                text-align: center;
                            }
                        }
                    }

                    .content__secondary-infos {
                        display: none;
                    }
                }
            }
        }
    }

    &.-more-elements-3 {
        .grid-list__wrapper {
            grid-template-rows: 1fr;

            .grid-list__item {
                grid-row: inherit;
                grid-column: inherit;
            }
        }
    }

    &.-more-elements-2 {
        .grid-list__wrapper {
            grid-template-columns: repeat(2, minmax(0, 25rem));
            grid-template-rows: 1fr;
        }
    }
}
