﻿.simple-images {
    text-align: center;
    padding: 0 $padding-side-page;
    display: flex;
    flex-direction: column;

    @media all and (max-width: 1500px) and (min-width: 901px) {
        padding: 0 calc($padding-side-page / 2);
    }

    @media all and (max-width: 900px) {
        padding: 0;
    }

    & > h2, & > p {
        padding: 0 1.25rem;
    }

    & > p {
        margin-top: 1.5625rem;
        max-width: 78.75rem;
        margin: 1.5625rem auto;

        & + .simple-images__wrapper {
            margin-top: 1.5625rem;
        }
    }

    .simple-images__wrapper {
        display: flex;
        justify-content: center;
        gap: inherit;
        margin-top: 3.125rem;

        @media all and (max-width: 1240px) {
            flex-direction: column;
            align-items: center;
        }

        @media all and (max-width: 900px) {
            h2 {
                padding: calc($padding-side-page / 5) calc($padding-side-page / 5)
            }
        }

        .simple-images__item {
            //max-width: 50rem;
            &.fbo {
                &:hover {
                    img {
                        filter: brightness(.7);
                    }
                }
            }

            img {
                object-fit: cover;
                transition: filter .5s;
            }
        }

        &.-slider {
            overflow: hidden;

            @media all and (max-width: 40rem) {
                flex-direction: row;
            }

            @import '../_owlSlider.scss';

            .simple-images__item {
                flex-shrink: 0;
                width: fit-content;

                img {
                    @media all and (max-width: 40rem) {
                        width: 300px;
                    }

                    @media all and (min-width: 40.063rem) {
                        width: 500px;
                    }
                }
            }
        }
    }

    &.-more-items-1 {
        .simple-images__wrapper {
            .simple-images__item {
                img {
                    max-width: 1000px;
                    width: 100%;
                }
            }
        }
    }

    &.-more-items-3, &.-more-items-5 {
        .simple-images__wrapper {
            gap: 1rem;
        }
    }
}
