﻿.tags-wrapper {
    display: flex;
    gap: .3125rem;
    flex-wrap: wrap;

    .tag {
        padding: .15625rem;
        text-transform: uppercase;
        display: inherit;

        span {
            font-size: .625rem;
        }

        &:has(img) {
            background: none !important;
        }

        img {
            height: 10px;
        }
    }
}
