﻿.cards-slider {
    background: #F2F2F2;
    padding: calc($padding-side-page / 2) 0 calc($padding-side-page / 2) $padding-side-page;

    @media all and (max-width: 1500px) and (min-width: 901px) {
        padding: calc($padding-side-page / 2) 0 calc($padding-side-page / 2) calc($padding-side-page / 2);
    }

    @media all and (max-width: 900px) and (min-width: 401px) {
        padding: calc($padding-side-page / 2) calc($padding-side-page / 5);
    }

    @media all and (max-width: 400px) {
        padding: calc($padding-side-page / 2) calc($padding-side-page / 10);
    }

    &.-no-background {
        padding-top: 0px;
        padding-bottom: 0px;
    }

    .cards-slider__wrapper {
        min-height: 630px;

        @media all and (min-width: 901px) {
            display: grid;
            grid-template-columns: 21.875rem auto;
            gap: 6.25rem;
            align-items: center;
        }

        @media all and (max-width: 1240px) {
            gap: 3.125rem;
        }

        @media all and (max-width: 900px) {
            display: flex;
            flex-direction: column;
        }

        .cards-slider__cards {
            width: 100%;
            max-width: 21.875rem;
            text-align: center;
            display: flex;
            gap: 3.125rem;
            flex-direction: column;
            justify-content: center;

            @media all and (max-width: 900px) {
                margin: auto;
            }

            @media all and (max-width: 450px) {
                max-width: 100%;
                margin: 0;
            }

            .cards__wrapper {
                height: 28.75rem;
                max-width: 21.875rem;
                overflow-x: hidden;

                .cards__item {
                    transition: all .1s;
                    cursor: grab;
                    background: white;
                    width: 100%;
                    height: 100%;
                    max-width: 21.875rem;
                    max-height: 28.75rem;
                    position: absolute;
                    box-shadow: 1px 3px 6px #00000029;

                    &:nth-child(n + 4) {
                        visibility: hidden;
                    }

                    @media all and (max-width: 450px) {
                        max-width: 90%;

                        &:nth-child(n + 3) {
                            display: none;
                        }
                    }

                    &[data-position="1"] {
                        z-index: 50;
                    }

                    .item__image {
                        max-height: 18.75rem;
                        height: 100%;

                        img {
                            width: 21.875rem;
                            max-height: 18.75rem;
                            aspect-ratio: 1;
                            object-fit: cover;

                            @media all and (max-width: 450px) {
                                width: 100%;
                            }
                        }
                    }

                    .item__content {
                        padding: 1.25rem .625rem .625rem .625rem;
                        height: 10rem;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        text-align: center;

                        .content__wrapper {
                            display: flex;
                            flex-direction: column;
                            gap: .625rem;

                            p {
                                overflow: hidden;
                                text-overflow: ellipsis;
                                display: -webkit-box;
                                -webkit-box-orient: vertical;
                                -webkit-line-clamp: 2;
                            }

                            .button {
                                width: fit-content;
                                margin: .625rem auto 0 !important;
                            }
                        }
                    }
                }
            }

            .cards__actions {
                display: flex;
                gap: 1.25rem;
                width: fit-content;
                margin: auto;

                .actions__previous {
                    button {
                        &:before {
                            content: '4';
                            font-family: 'ElegantIcons';
                            font-size: 2rem;
                        }
                    }
                }

                .actions__next {
                    button {
                        &:before {
                            content: '5';
                            font-family: 'ElegantIcons';
                            font-size: 2rem;
                        }
                    }
                }

                .actions__indicator {
                    display: flex;
                    gap: .3125rem;
                    align-items: center;

                    .indicator {
                        &.-dot {
                            width: .3125rem;
                            height: .3125rem;
                            border-radius: 50%;
                            border: solid .5px #333;

                            &.-active {
                                background: #333;
                            }
                        }
                    }
                }
            }
        }

        .cards-slider__slider {
            min-height: 520px;
            overflow: hidden;
            display: flex;
            flex-direction: column;
            gap: 3.125rem;
            text-align: center;
            justify-content: center;

            @media all and (max-width: 640px) {
                gap: 1rem;
            }

            & > * {
                padding-right: calc($padding-side-page * 1.3);

                @media all and (max-width: 1500px) and (min-width: 901px) {
                    padding-right: calc($padding-side-page / 2 * 1.6 );
                }

                @media all and (max-width: 900px) {
                    padding: inherit;
                }
            }

            .slider__title-h2 {
                display: flex;
                gap: .625rem;
                font-weight: bold;
                align-items: flex-end;
                flex-wrap: wrap;
                justify-content: center;

                a {
                    @media all and (max-width: 640px) {
                        display: block;
                        width: 100%;
                    }
                }
            }

            .slider__logo {
                width: 2.1875rem;
                margin: auto;
            }

            @import '../_owlSlider.scss';

            .slider__wrapper {
                //height: 350px;
                display: flex;
                gap: 2.5rem;

                &:not(.owl-carousel) {
                    justify-content: center;
                }

                .slider__item {
                    //max-width: 18.75rem;
                    //height: 21.875rem;
                    width: var(--width-item);
                    max-width: 100%;
                    display: flex;
                    flex-direction: column;
                    gap: 1.25rem;

                    &:not(.-no-link) {
                        &:hover {
                            .item__image {
                                .image__secondary {
                                    img {
                                        opacity: 1 !important;
                                    }
                                }
                            }
                        }
                    }

                    .item__image {
                        width: 100%;
                        height: 100%;
                        position: relative;

                        .image__primary {
                            height: 100%;
                            overflow: hidden;

                            img {
                                //height: 100%;
                                height: unset;
                                width: var(--width-item);
                                max-width: inherit;
                                object-fit: cover;
                                transition: all .5s;

                                &.js-lazyLoad {
                                    height: 1px;
                                }
                            }
                        }

                        .image__secondary { 
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            max-width: 12.1875rem;
                            width: 100%;

                            @media all and (max-width: 640px) {
                                display: none;
                            }

                            img {
                                max-height: 100%;
                                max-width: 100%;
                                width: 100%;
                                opacity: 0;
                                transition: opacity .5s;
                            }
                        }
                    }

                    .item__content {
                        display: flex;
                        flex-direction: column;
                        gap: .875rem;
                    }
                }

                .owl-dots {
                    display: none !important;
                }

                .owl-nav {
                    display: unset;

                    .owl-prev {
                        display: none;
                    }

                    .owl-next {
                        @media all and (max-width: 400px) {
                            right: .125rem;
                        }
                    }
                }
            }
        }

        &.-no-cards {
            display: block;
            min-height: inherit;

            .cards-slider__slider {
                display: block;
                min-height: inherit;

                &:has(h3) {
                    h2 {
                        margin-bottom: .875rem;
                    }
                }

                .slider__wrapper {
                    margin-top: 2.5rem;
                    height: inherit;

                    @media all and (max-width: 64rem) {
                        .owl-nav {
                            display: none;
                        }
                    }

                    .slider__item {
                        height: inherit;
                    }
                }
            }
        }
    }
}
